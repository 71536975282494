import React, {createRef} from "react";

import * as Yup from "yup";

import {
  Button,
  Form,
  Grid,
  Header,
  Message,
  Segment,
  Icon,
  Loader,
  Dimmer,
  Image,
  Divider,
  Popup,
  Transition
} from "semantic-ui-react";

import authApi from "../apis/auth";

import { STATIC_FILE_PATH } from "../applicationConstants";

const LoginFormHeader = ({ text }) => (
  <Grid stackable celled="internally">
    <Grid.Row>
      <Grid.Column width={6} textAlign="right">
        <Image
          src={`${process.env.PUBLIC_URL}/nse_ofs_logo_new.png`}
          size="medium"
          verticalAlign="middle"
        />
      </Grid.Column>
      <Grid.Column width={10} verticalAlign="middle" textAlign="left">
        <Header as="h3" color="orange">
          {text}
        </Header>
      </Grid.Column>
    </Grid.Row>
  </Grid>
);

export default class ForgotPassword extends React.Component {
  state = {
    formStage: 0,
    passwordForm: {
      userId: "",
      mobileNumber: "",
      emailId: ""
    },
    otpForm: {
      otp: ""
    },
    interimToken: null,
    otpAttemptCount: 5,
    confirmBox: null,
    isConfirmationOpen: false,
    isSubmitting: false,
    successMessage: null,
    errors: {},
    touched: {},
    errorArray: [],
    randomstring : null
  };

  validationSchema = Yup.object().shape({
    userId: Yup.number()
      .typeError("Enter a valid User ID")
      .required("User ID cannot be blank"),
    mobileNumber: Yup.string()
      .required("Mobile number cannot be blank")
      .length(10, "Mobile number must have 10 digits")
      .matches(/^[0-9]*$/, "Invalid Mobile number."),
    emailId: Yup.string()
      .required("Email ID is mandatory")
      .email("Enter a valid Email ID"),
      captcha:Yup.string()
      .required("Please Enter Captcha")
  });

  validationSchema_withoutcaptcha = Yup.object().shape({
    userId: Yup.number()
      .typeError("Enter a valid User ID")
      .required("User ID cannot be blank"),
    mobileNumber: Yup.string()
      .required("Mobile number cannot be blank")
      .length(10, "Mobile number must have 10 digits")
      .matches(/^[0-9]*$/, "Invalid Mobile number."),
    emailId: Yup.string()
      .required("Email ID is mandatory")
      .email("Enter a valid Email ID")
  });

  

  constructor(props) {
    super(props);
    this.canvasRef = createRef();
  }
  componentDidMount() {
    authApi
    .get(`/validate-captcha-flag`)
     .then(response => {
      this.setState({captchaenabled: response.data});
      this.initializeCaptcha();
     });
    // this.setState({randomstring: Math.random().toString(36).slice(8)});
    
  }
  refreshString = (e) =>{
   e.preventDefault();
   this.setState({randomstring: Math.random().toString(36).slice(8)});
 };
generateCaptchaText = () => {
  // Replace this with your own CAPTCHA generation logic
  return Math.random().toString(36).slice(8);
};

drawCaptchaOnCanvas = (ctx, captcha) => {
  ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
  const textColors = ['rgb(255,255,255)', 'rgb(255,255,255)'];
  const letterSpace = 150 / captcha.length;
  const canvasCenterX = ctx.canvas.width / 2;
  const textTotalWidth = captcha.length * letterSpace;
  const startX = canvasCenterX - textTotalWidth / 2;

  for (let i = 0; i < captcha.length; i++) {
    ctx.font = '40px Arial';
    ctx.textAlign = 'center';
    ctx.fillStyle = textColors[Math.floor(Math.random() * 2)];
    ctx.fillText(
      captcha[i],
      startX + i * letterSpace,
      Math.floor(Math.random() * 16 + 30),
      100
    );
  }
};  

initializeCaptcha = () => {
  const newCaptcha = this.generateCaptchaText();
  const canvas = this.canvasRef.current;
  if (canvas) {
    const ctx = canvas.getContext('2d');
    this.drawCaptchaOnCanvas(ctx, newCaptcha);
    this.setState({ newCaptcha: newCaptcha });
  } else {
    console.error('Canvas not found');
  }
};

refreshCaptcha = (e) => {
  e.preventDefault();
  const newCaptcha = this.generateCaptchaText();
  const canvas = this.canvasRef.current;
  if (canvas) {
    const ctx = canvas.getContext('2d');
    this.drawCaptchaOnCanvas(ctx, newCaptcha);
    this.setState({ newCaptcha: newCaptcha });
  } else {
    console.error('Canvas not found');
  }
};
  /**
   * Form Data Handlers
   */
  handleInput = async (e, fieldProps) => {
    let value = fieldProps.value;
    let name = fieldProps.name;
    let currentState = { ...this.state };

    // Set data of changed field in copied state
    currentState.passwordForm[name] = value;
    currentState.touched[name] = true;

    // Validate the form
    currentState = this.validateForm(currentState, false);

    this.setState(currentState, () => console.log(this.state));
  };

  validateForm = (currentState, isValidateOnSubmit) => {
    try {
      const isValid = this.state.captchaenabled == 1 ? this.validationSchema.validateSync(currentState.passwordForm, {
        abortEarly: false
      }) : this.validationSchema_withoutcaptcha.validateSync(currentState.passwordForm, {
        abortEarly: false
      });
      console.log(isValid);

      // Reset error object and array as all the validation checks have passed
      currentState.errors = {};
      currentState.errorArray = [];
    } catch (validationError) {
      console.log(validationError);

      // Set validation messages as per the field name
      let errors = {};
      let errorArray = [];

      validationError.inner.map(errorDetail => {
        if (!errors[errorDetail.path]) {
          errors[errorDetail.path] = [];
        }
        errors[errorDetail.path].push(errorDetail.message);

        // Add message to flat message array if the field has been touched
        if (currentState.touched[errorDetail.path] || isValidateOnSubmit)
          errorArray.push(errorDetail.message);
      });
      currentState.errors = errors;
      currentState.errorArray = errorArray;
    }

    return currentState;
  };

  handleCaptchaKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent form submission when Enter key is pressed
    }
  };

  handleOTPInput = (e, fieldProps) => {
    let currentState = { ...this.state };
    let value = fieldProps.value;

    currentState.otpForm.otp = value;

    let validationMessages = [];
    if (!value) validationMessages.push("Please enter OTP received on your mobile and email-id");
    if (isNaN(value)) validationMessages.push("Please enter a valid OTP");

    currentState.errorArray = validationMessages;

    this.setState(currentState);
  };

  handleFormSubmit = (e) => {
    e.preventDefault();
    this.handleFormSubmit();
  };

  handleSubmitEnterPress = (e) => {
    
    if(e.key === 'Enter')
    {
      e.preventDefault();
      this.handleFormSubmit();
    }
  };

  handleFormSubmit = e => {
   // e.preventDefault();

    // Form submission logic
    console.log(this.state.passwordForm);

    // Validate form fields.
    let currentState = { ...this.state };
    currentState = this.validateForm(currentState, true);
    if (currentState.errorArray.length !== 0) {
      this.setState({ errorArray: currentState.errorArray });
      return;
    }

    this.setState({ isSubmitting: true });

    // Submit data if there are no validation failures
    authApi
     // .post("/forgot-password", this.state.passwordForm)
     .post(`/forgot-password?captcha=${this.state.passwordForm.captcha}&&captchaf=${this.state.newCaptcha}`,this.state.passwordForm)
       .then(response => {
        const responseData = response.data;

        if (responseData) {
          // IF there are validation errors
          if (responseData.messages && responseData.messages.length > 0) {
            this.setState({
              isSubmitting: false,
              errorArray: [...responseData.messages]
            });
          } else {
            // In case of successful validation
            const interimToken = responseData.accessToken;

            this.setState({ isSubmitting: false, interimToken, errorArray: [], formStage: 1 }, () =>
              console.log(this.state)
            );
          }
        }
      })
      .catch(error => {
        let errorMessage = "";

        errorMessage = "Something went wrong.";

        this.setState({
          errorArray: [errorMessage],
          successMessage: null,
          confirmBox: null,
          isSubmitting: false
        });
      });
  };
  onOTPSubmitClick = () => {
    const { otp } = this.state.otpForm;
    const { userId } = this.state.passwordForm;
    const { interimToken } = this.state;

    let formData = { otp, userId, token: interimToken, purpose: "PasswordChange" };
    console.log(formData);
    let { otpAttemptCount } = this.state;
    if (otpAttemptCount <= 1) {
      // Max attempts reached
    }

    this.setState({ otpAttemptCount: otpAttemptCount - 1 });

    this.setState({ isSubmitting: true });
    // Flags for handling the login error thrown by the Auth server

    authApi
      .post("/validate-forgot-password", formData)
      .then(response => {
        const responseData = response.data;

        if (responseData) {
          // IF there are validation errors
          if (responseData.messages && responseData.messages.length > 0) {
            this.setState({
              isSubmitting: false,
              errorArray: [...responseData.messages]
            });
          } else {
            // In case of successful validation
            if (responseData.accessToken) {
              window.location.href = `${STATIC_FILE_PATH}#/password-expired/${responseData.accessToken}/3`;
            } else {
              this.setState({
                errorArray: ["Error occured while verifying OTP"],
                isSubmitting: false
              });
            }
          }
        }
      })
      .catch(error => {
        this.setState({
          errorArray: ["Error occured while verifying OTP"],
          isSubmitting: false
        });
        let responseData = {};
        if (error.response) responseData = error.response.data;
        console.log(responseData);
      });
    return false;
  };
  renderErrorSymmary = () => {
    const { errorArray } = this.state;

    if (!errorArray || errorArray.length === 0) return null;

    const errorMessage = errorArray[0];

    return (
      <Message error>
        <Icon name="warning sign" />
        {errorMessage}
      </Message>
    );
  };

  renderCaptchaError = () => {
    if(this.state.loadingCaptcha != "")
    {
      return "Invalid Captcha";
    }
  };

  renderForgotPasswordForm = () => {
    const { errorArray, touched } = this.state;
    const { userId, mobileNumber, emailId } = this.state.passwordForm;

    const handleUsernameChange = (event) => {
      console.log(handleUsernameChange);
      const key = event.key;
      if (key === 'Enter') {
        event.preventDefault(); // Prevent form submission when Enter key is pressed
      }
      if(!/[a-zA-Z0-9_]/.test(key)){
        event.preventDefault();
      }};

    return (
      <React.Fragment>
        <Form size="large" onSubmit={this.handleFormSubmit} className="error">
          <Transition animation="browse" duration={400}>
            <Segment stacked>
              <LoginFormHeader text="Forgot Password" />
              <Divider />
              <Popup
                trigger={
                  <Form.Input
                    fluid
                    name="userId"
                    value={userId}
                    icon="user"
                    iconPosition="left"
                    placeholder="User ID"
                    type="number"
                    autoComplete="off"
                    maxLength="30"
                    onChange={this.handleInput}
                    onKeyPress={handleUsernameChange}
                  />
                }
                on="focus"
                content="Enter User ID"
                position="bottom right"
                inverted
                size="tiny"
              />
              <Popup
                trigger={
                  <Form.Input
                    fluid
                    name="mobileNumber"
                    value={mobileNumber}
                    icon="mobile"
                    iconPosition="left"
                    placeholder="Mobile Number"
                    type="number"
                    autoComplete="off"
                    maxLength="10"
                    onChange={this.handleInput}
                    onKeyPress={this.handleCaptchaKeyPress}
                   // autoComplete={false}
                  />
                }
                on="focus"
                content="Enter Mobile Number"
                position="bottom right"
                inverted
                size="tiny"
              />
              <Popup
                trigger={
                  <Form.Input
                    fluid
                    name="emailId"
                    value={emailId}
                    icon="mail"
                    iconPosition="left"
                    placeholder="Email ID"
                    autoComplete="off"
                    maxLength="256"
                    onChange={this.handleInput}
                    onKeyPress={this.handleCaptchaKeyPress} 
                   // autoComplete={false}
                  />
                }
                on="focus"
                content="Enter Email ID"
                position="bottom right"
                inverted
                size="tiny"
              />
              {this.state.captchaenabled  == 1 ?
        <Form.Group widths="equal">
            {/* <div className="captchadiv">{this.state.randomstring}
            <style>{`
           .captchadiv {
            color:#fff;
            text-decoration: line-through;
            user-select: none;
            background-color: #f2711c;
            border-radius: 5pa;
            width: 203.48px;
            height: 36px;
            font-size:22px;
            margin-bottom: auto;
            margin-top: 5px;
            margin-left: 7px;
            display: flex;
            text-align:center;
            justify-content:center;
            align-items: center;
        }
    `}</style>
            </div> */}
             <canvas className="captchadiv" ref={this.canvasRef}
                        width="200"
                        height="70">
 <style>{`
      .captchadiv {
        color:#fff;
        text-decoration: line-through;
        user-select: none;
        background-color: #f2711c;
        border-radius: 5pa;
        width: 203.48px;
        height: 36px;
        font-size:100px;
        margin-bottom: auto;
        margin-top: 5px;
        margin-left: 7px;
        display: flex;
        text-align:center;
        justify-content:center;
        align-items: center;
    }
    `}</style>
                    </canvas>
            <Form.Input
              fluid
              label=""
              placeholder="Captcha"
              name="captcha"
              onChange={this.handleInput}
              onKeyPress={this.handleSubmitEnterPress} // Attach handleCaptchaKeyPress function here
            />
            <Button onClick={this.refreshCaptcha} >
            <Icon name="refresh" />
            </Button>
            </Form.Group>
            : null }
              <Button color="orange" fluid size="large">
                <Icon name="sign-in" /> Submit
              </Button>
            </Segment>
          </Transition>
        </Form>
        {this.state.loadingCaptcha ?
        <Message error>
          <Message.Content>
            {this.renderCaptchaError()}
          </Message.Content>
        </Message>
        : null}
        {!touched.userId &&
        !touched.mobileNumber &&
        !touched.emailId &&
        !touched.captcha &&
        (!errorArray || errorArray.length === 0)
          ? null
          : this.renderErrorSymmary()}
      </React.Fragment>
    );
  };

  renderOTPForm = () => {
    const { otpAttemptCount, otpForm } = this.state;
    return (
      <React.Fragment>
        <Form size="large" onSubmit={this.onOTPSubmitClick} className="error">
          <Segment stacked>
            <LoginFormHeader text="OTP verification" />
            <Divider />

            <Popup
              trigger={
                <Form.Input
                  fluid
                  name="otp"
                  value={otpForm.otp}
                  icon="lock"
                  iconPosition="left"
                  placeholder="Enter OTP"
                  type="text"
                  autoComplete="off"
                  maxLength="7"
                  onChange={this.handleOTPInput}
                />
              }
              on="focus"
              content="Enter OTP"
              position="bottom right"
              inverted
              size="tiny"
            />
            <Button.Group fluid>
              <Button color="orange" size="large">
                <Icon name="check circle" /> Verify
              </Button>
              {/* <Button size="large" onClick={this.handleLoginWithoutOtp} type="button">
                <Icon name="sign-in" /> Login without OTP
              </Button> */}
            </Button.Group>
            <hr />
            <div>Remaining attemps: {otpAttemptCount}</div>
          </Segment>
        </Form>

        {this.renderErrorSymmary()}
      </React.Fragment>
    );
  };
  renderFormStage = () => {
    const { formStage } = this.state;
    if (formStage === 0) return this.renderForgotPasswordForm();
    if (formStage === 1) return this.renderOTPForm();
    else return null;
  };

  render() {
    const { isSubmitting } = this.state;

    return (
      <React.Fragment>
        <Dimmer active={isSubmitting}>
          <Loader active={isSubmitting} content="Please wait.." />
        </Dimmer>

        <div
          className="expiry-form background-image"
          style={{
            background: `url(${STATIC_FILE_PATH}Building_Image_4_full_dimmed_compressed.jpg) no-repeat center center fixed`,
            WebkitBackgroundSize: "cover",
            MozBackgroundSize: "cover",
            OBackgroundSize: "cover",
            backgroundSize: "cover"
          }}
        >
          <style>{`
            body > div#root,
            body > div > div,
            body > div > div > div.expiry-form {
              height: 100%;
            }
          `}</style>
          <Grid textAlign="center" style={{ height: "100%" }} verticalAlign="middle" relaxed>
            <Grid.Column style={{ maxWidth: 450 }}>{this.renderFormStage()}</Grid.Column>
          </Grid>
        </div>
      </React.Fragment>
    );
  }
}
